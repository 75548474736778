@import url('https://fonts.googleapis.com/css2?family=Arbutus+Slab&family=Urbanist:wght@300;400;500;600;700&display=swap');

body {
      font-family: 'Arbutus Slab', serif, 'Urbanist', sans-serif;
      overflow-x: hidden;
}

a {
      text-decoration: none;
      color: currentColor;
}

a:hover {
      color: currentColor;
}

nav {
      height: 70px; 
      background-color: #fff;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 25%);
      display: flex;
      align-items: center;
}

nav .container-fluid {
      padding: 0px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
}

nav, h5 {
      padding: auto 0px;
      cursor: pointer;
      
}

nav .navb-items {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      letter-spacing: 3px;
}

nav .item {
      text-align: center;
      margin-inline: 1.2%;
      font-size: 20px;
      letter-spacing: 3px;
      padding: 3px 0px;
      transition: all .1s ease;
      cursor: pointer;
}

/* modal */
.modal-dialog {
      margin: 0px;
      width: 250px;
}

.modal-content {
      border-radius: 0px;
      height: 100vh;
      overflow-y: scroll;
}

.modal-header {
      display:flex;
      justify-content: space-between;
      align-items: center;
      width: 88%;
      margin: 0px auto;
      padding-bottom: 16px;
      border-bottom: 2px solid black;
}

.modal-header h5 {
      width: 145px;
      height: 25px;
      margin-top: 17.5px;
}